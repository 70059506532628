import React, { useRef, useState, useEffect, useContext } from "react";
import { Button, Col, Row, Spin, Switch } from "antd";
import * as faceapi from "face-api.js";
import { LoginContext } from "../rawatjalan/context";

const FaceRecognition = () => {
  const { signIn } = useContext(LoginContext);
  const videoRef = useRef();
  const canvasRef = useRef();
  const [nama, setNama] = useState("");
  const [loadingcamera, setLoadingCamera] = useState(false);
  const [isWebcamActive, setIsWebcamActive] = useState(false);
  const [isModelLoaded, setIsModelLoaded] = useState(false);
  const [labeledFaceDescriptors, setLabeledFaceDescriptors] = useState(null);
  const [videoStream, setVideoStream] = useState(null);
  const [isDetecting, setIsDetecting] = useState(false);
  const [showCanvas, setShowCanvas] = useState(false);
  const [faceDetected, setFaceDetected] = useState(false);
  const [noFaceDetectionCount, setNoFaceDetectionCount] = useState(0); // Hitung kegagalan deteksi

  useEffect(() => {
    const loadModels = async () => {
      try {
        await faceapi.nets.ssdMobilenetv1.loadFromUri("/models");
        await faceapi.nets.faceLandmark68Net.loadFromUri("/models");
        await faceapi.nets.faceRecognitionNet.loadFromUri("/models");
        console.log("Model berhasil dimuat");

        const labeledDescriptors = await loadLabeledImages();
        setLabeledFaceDescriptors(labeledDescriptors);
        setIsModelLoaded(true);
      } catch (err) {
        console.error("Error loading models:", err);
      }
    };

    loadModels();
  }, []);

  const loadLabeledImages = async () => {
    const labels = ["Nugraha", "Heru", "Danu"]; // Tambahkan label sesuai model yang ada
    return Promise.all(
      labels.map(async (label) => {
        const img = await faceapi.fetchImage(`/labeled_images/${label}.jpg`);
        const detections = await faceapi
          .detectSingleFace(img)
          .withFaceLandmarks()
          .withFaceDescriptor();
        if (!detections) {
          throw new Error(`Tidak ada wajah terdeteksi untuk ${label}`);
        }

        return new faceapi.LabeledFaceDescriptors(label, [
          detections.descriptor,
        ]);
      })
    );
  };

  // Fungsi untuk menyalakan dan mematikan webcam berdasarkan status Switch
  const handleSwitchChange = (checked) => {
    if (checked) {
      startVideo(); // Nyalakan Webcam
      setLoadingCamera(true);
    } else {
      stopVideo(); // Matikan Webcam
    }
  };

  const startVideo = () => {
    navigator.mediaDevices
      .getUserMedia({ video: {} })
      .then((stream) => {
        videoRef.current.srcObject = stream;
        setVideoStream(stream);
        setIsWebcamActive(true);
        setShowCanvas(true);
        setIsDetecting(true);
        console.log("Webcam berhasil dinyalakan");
        setLoadingCamera(false);
      })
      .catch((err) => {
        console.error("Error accessing webcam: ", err);
        setIsWebcamActive(false);
        setLoadingCamera(false);
      });
  };

  const stopVideo = () => {
    if (videoStream) {
      videoStream.getTracks().forEach((track) => track.stop()); // Menghentikan semua stream
      videoRef.current.pause(); // Menghentikan pemutaran video
      videoRef.current.srcObject = null; // Mengosongkan sumber video
      setIsWebcamActive(false);
      setIsDetecting(false);
      setShowCanvas(false);
      setFaceDetected(false);
      setVideoStream(null);
      setNama("");
      setNoFaceDetectionCount(1); // Reset count kegagalan deteksi wajah menjadi 1
      console.log("Webcam berhasil dimatikan, deteksi dihentikan");
    }
  };

  const handleVideoPlay = async () => {
    if (!isModelLoaded || !labeledFaceDescriptors) {
      console.log("Model atau deskriptor wajah belum siap");
      return;
    }

    const video = videoRef.current;
    const canvas = canvasRef.current;

    if (!canvas) {
      console.error("Canvas tidak tersedia");
      return; // Pastikan canvas ada sebelum melanjutkan
    }

    const displaySize = {
      width: video.videoWidth,
      height: video.videoHeight,
    };

    faceapi.matchDimensions(canvas, displaySize);

    const detectFaces = async () => {
      if (!isDetecting || !isWebcamActive) {
        console.log("Deteksi dihentikan karena webcam sudah dimatikan");
        return;
      }

      const detections = await faceapi
        .detectAllFaces(video, new faceapi.SsdMobilenetv1Options())
        .withFaceLandmarks()
        .withFaceDescriptors();

      console.log("Deteksi wajah dari webcam:", detections);

      const resizedDetections = faceapi.resizeResults(detections, displaySize);
      canvas.getContext("2d").clearRect(0, 0, canvas.width, canvas.height);
      faceapi.draw.drawDetections(canvas, resizedDetections);
      faceapi.draw.drawFaceLandmarks(canvas, resizedDetections);

      const faceMatcher = new faceapi.FaceMatcher(labeledFaceDescriptors, 0.6);
      const results = resizedDetections.map((d) =>
        faceMatcher.findBestMatch(d.descriptor)
      );

      const isFaceDetected = results.some(
        (result) => result.label !== "unknown"
      );
      setFaceDetected(isFaceDetected);

      // Update count kegagalan deteksi
      if (detections.length === 0) {
        setNoFaceDetectionCount((prevCount) => prevCount + 1);
        console.log(`Kegagalan deteksi wajah: ${noFaceDetectionCount + 1}`);
      } else {
        setNoFaceDetectionCount(0); // Reset hitungan jika wajah terdeteksi
      }

      if (noFaceDetectionCount >= 10) {
        console.log(
          "Tidak ada wajah terdeteksi lebih dari 10 kali, menghentikan deteksi."
        );
        stopVideo(); // Hentikan deteksi dan matikan webcam
        return; // Keluar dari fungsi
      }

      results.forEach((result, i) => {
        console.log(`Wajah dikenali: ${result.toString()}`);
        setNama(`${result.toString()}`);
        console.log("Result : " + result);
      });

      // Panggil detectFaces lagi setelah 1 detik
      if (isDetecting && isWebcamActive) {
        setTimeout(() => {
          requestAnimationFrame(detectFaces);
        }, 1000); // Set interval 1 detik
      }
    };

    detectFaces(); // Panggilan awal
  };

  return (
    <div>
      <Spin
        size="large"
        percent="auto"
        spinning={!isModelLoaded}
        tip="Memuat Models"
      >
        <div style={{ marginBottom: "16px" }}>
          <Row gutter={[8, 8]}>
            <Col span={12}>
              <Switch
                checked={isWebcamActive}
                onChange={handleSwitchChange}
                checkedChildren="Matikan Webcam"
                unCheckedChildren="Nyalakan Webcam"
                disabled={!isModelLoaded}
                loading={loadingcamera}
              />
            </Col>
            <Col span={12} style={{ textAlign: "right" }}>
              {faceDetected && (
                <div>
                  Anda terdeteksi : {nama}
                  {"  "}
                  <Button
                    type="primary"
                    onClick={() => {
                      stopVideo();
                      if (nama.includes("Nugraha")) {
                        signIn("nugraha", "Jenengku");
                      } else if (nama.includes("Heru")) {
                        signIn("heru", "heru123");
                      } else if (nama.includes("Danu")) {
                        signIn("danu", "danu12123");
                      }
                    }}
                  >
                    Login
                  </Button>
                </div>
              )}
            </Col>
          </Row>
        </div>
        <div style={{ position: "relative", width: "640px", height: "480px" }}>
          <video
            ref={videoRef}
            autoPlay
            muted
            onPlay={handleVideoPlay}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
          />
          {showCanvas && (
            <canvas
              ref={canvasRef}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
            />
          )}
        </div>
      </Spin>
    </div>
  );
};

export default FaceRecognition;
