import { DislikeTwoTone } from '@ant-design/icons'
import { Spin } from 'antd'
import React from 'react'

const MainFarmasi = () => {
  return (
    <div>Kie sing diutak atik</div>
  )
}

export default MainFarmasi