import React, { useState, useRef, useEffect } from "react";
import { Button, Input } from "antd";
import * as faceapi from "face-api.js";

const FaceEnrollment = ({ onEnrollmentSuccess }) => {
  const videoRef = useRef();
  const [isWebcamActive, setIsWebcamActive] = useState(false);
  const [isModelLoaded, setIsModelLoaded] = useState(false);
  const [videoStream, setVideoStream] = useState(null);
  const [username, setUsername] = useState("");
  const [isEnrolling, setIsEnrolling] = useState(false);
  const [enrollmentSuccess, setEnrollmentSuccess] = useState(false);

  useEffect(() => {
    const loadModels = async () => {
      try {
        await faceapi.nets.ssdMobilenetv1.loadFromUri("/models");
        await faceapi.nets.faceLandmark68Net.loadFromUri("/models");
        await faceapi.nets.faceRecognitionNet.loadFromUri("/models");
        console.log("Model berhasil dimuat");
        setIsModelLoaded(true);
      } catch (err) {
        console.error("Error loading models:", err);
      }
    };

    loadModels();

    return () => {
      if (isWebcamActive) {
        stopVideo();
      }
    };
  }, [isWebcamActive]);

  const startVideo = () => {
    navigator.mediaDevices
      .getUserMedia({ video: {} })
      .then((stream) => {
        videoRef.current.srcObject = stream;
        setVideoStream(stream);
        setIsWebcamActive(true);
        console.log("Webcam berhasil dinyalakan");
      })
      .catch((err) => {
        console.error("Error accessing webcam: ", err);
        setIsWebcamActive(false);
      });
  };

  const stopVideo = () => {
    if (videoStream) {
      videoStream.getTracks().forEach((track) => track.stop());
      videoRef.current.pause();
      videoRef.current.srcObject = null;
      setIsWebcamActive(false);
      setVideoStream(null);
      console.log("Webcam berhasil dimatikan");
    }
  };

  const handleEnroll = async () => {
    if (!username) {
      alert("Mohon masukkan username!");
      return;
    }

    if (!isModelLoaded) {
      alert("Model belum siap!");
      return;
    }

    setIsEnrolling(true);

    const video = videoRef.current;
    const detections = await faceapi
      .detectSingleFace(video)
      .withFaceLandmarks()
      .withFaceDescriptor();

    if (detections) {
      const descriptor = detections.descriptor;
      const user = {
        username: username,
        descriptor: descriptor,
      };
      // Simpan user baru di localStorage atau kirim ke server
      localStorage.setItem(username, JSON.stringify(user));
      console.log("Enrollment berhasil:", user);

      setEnrollmentSuccess(true);
      onEnrollmentSuccess && onEnrollmentSuccess(user); // Callback setelah berhasil

      stopVideo();
    } else {
      alert("Tidak ada wajah yang terdeteksi. Coba lagi.");
    }

    setIsEnrolling(false);
  };

  return (
    <div>
      <h1>Enroll Wajah Baru</h1>
      <Input
        placeholder="Masukkan Username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        style={{ marginBottom: "16px" }}
      />

      <Button
        type="primary"
        onClick={startVideo}
        disabled={isWebcamActive || !isModelLoaded}
        style={{ marginBottom: "16px" }}
      >
        Nyalakan Webcam
      </Button>
      <Button
        onClick={stopVideo}
        disabled={!isWebcamActive}
        style={{ marginLeft: "8px", marginBottom: "16px" }}
      >
        Matikan Webcam
      </Button>
      {enrollmentSuccess && <p>Wajah berhasil didaftarkan untuk {username}!</p>}
      <div style={{ position: "relative", width: "640px", height: "480px" }}>
        <video
          ref={videoRef}
          autoPlay
          muted
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
        />
      </div>

      <Button
        type="primary"
        onClick={handleEnroll}
        disabled={!isWebcamActive || isEnrolling}
        style={{ marginTop: "16px" }}
      >
        {isEnrolling ? "Mendaftarkan..." : "Daftarkan Wajah"}
      </Button>
    </div>
  );
};

export default FaceEnrollment;
