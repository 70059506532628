import {
  Button,
  Card,
  Checkbox,
  DatePicker,
  Divider,
  Empty,
  Input,
  Popconfirm,
  Popover,
  Select,
  Space,
  Table,
  Tooltip,
  Typography,
  message,
} from "antd";
import React, { useContext, useState } from "react";
import { PasienContext } from "../rawatjalan/context/PasienContext";
import axios from "axios";
import { PelayananContext } from "../rawatjalan/context/Pelayanancontext";
import dayjs from "dayjs";
import {
  DeleteFilled,
  DeleteTwoTone,
  EditTwoTone,
  QuestionCircleTwoTone,
} from "@ant-design/icons";
const { Option } = Select;
const { Text } = Typography;
const { Column } = Table;
const InsertBill = () => {
  const { pembayaran, getPembayaran } = useContext(PasienContext);
  const { dokterall } = useContext(PelayananContext);
  const [billing, setBilling] = useState([]);
  const [pelayanan, setPelayanan] = useState([]);
  const [noreg, setNoreg] = useState(null);
  const [ruang, setRuang] = useState(null);
  const [unit, setUnit] = useState([]);
  const [pel, setPel] = useState(null);
  const [pem, setPem] = useState(null);
  const [tanggal, setTanggal] = useState(dayjs());
  const [dokter, setDokter] = useState(null);
  const [user, setUser] = useState(null);
  const [client, setClient] = useState(null);
  const [ip, setIP] = useState(null);
  const [load, setLoad] = useState(false);

  const data = {
    registrasiId: noreg,
    ruangId: ruang,
    pelayananId: pel,
    pembayaranId: pem,
    dokterPemeriksaId: dokter,
    kdgrptrf: "3",
    jmlPelayanan: 1,
    biayaPelayanan: 350000,
    tglPelayanan: dayjs(tanggal.format("YYYY-MM-DD HH:mm:ss")),
    userId: user,
    clientHost: client,
    clientIp: ip,
  };

  const apiku = sessionStorage.getItem("api");
  const tok = sessionStorage.getItem("userData");
  const options = {
    headers: { Authorization: "Bearer " + tok },
  };

  const detailBilling = (id) => {
    setLoad(true);
    axios
      .get(`${apiku}/BillPemeriksaan/Read/${id}/1/10`, options)
      .then((res) => {
        if (res.data.statusCode === 200) {
          setBilling(res.data.result);
        } else {
          message.warning(res.data.message);
          setBilling([]);
        }
      })
      .catch((err) => {
        setBilling([]);
      })
      .finally(() => setLoad(false));
  };

  const loadPelayanan = (ruang) => {
    axios
      .get(`${apiku}/MstStandarPelayananRuang/Read/${ruang}/1/200`, options)
      .then((res) => {
        if (res.data.statusCode === 200) {
          setPelayanan(res.data.result);
        } else {
          setPelayanan([]);
          message.warning(res.data.message);
        }
      })
      .catch((err) => {
        setPelayanan([]);
        console.log(err);
        message.error("Gagal mengambil data!");
      });
  };

  const loadRuang = (jenis) => {
    axios
      .get(`${apiku}/MstRuang/Lookup/%20/${jenis}/1/100`, options)
      .then((res) => {
        if (res.data.statusCode === 200) {
          setUnit(res.data.result);
        } else {
          setUnit([]);
          message.warning(res.data.message);
        }
      })
      .catch((err) => {
        setUnit([]);
        console.log(err);
        message.error("Gagal mengambil data!");
      });
  };

  const insertBiiling = (databilling) => {
    axios
      .post(`${apiku}/BillPemeriksaan/`, databilling, {
        headers: options.headers,
      })
      .then((res) => {
        if (res.data.statusCode === 200) {
          message.success("Berhasil Disimpan!");
        } else {
          console.log(res.data);
          message.warning(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err.response);
        message.error("Gagal Disimpan!");
      })
      .finally(() => setLoad(false));
  };

  return (
    <div>
      <Card
        loading={load}
        size="small"
        title="Insert Billing"
        headStyle={{ fontWeight: "bolder", backgroundColor: "aliceblue" }}
        style={{
          borderWidth: "2px",
          borderColor: "darkgray",
          borderRadius: "4px",
        }}
      >
        <Input
          placeholder="No Registrasi"
          onChange={(e) => setNoreg(e.target.value)}
          onPressEnter={(e) => detailBilling(e.target.value)}
          value={noreg}
        />
        <DatePicker
          style={{ width: "100%" }}
          placeholder="Pilih Tanggal"
          onChange={(date, dateString) => setTanggal(date)}
        />
        <Select
          showSearch
          style={{ width: "100%" }}
          placeholder="Pilih Jenis Ruang..."
          optionFilterProp="children"
          onChange={(e) => {
            loadRuang(e);
          }}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          <Option key={"1"}>RawatInap</Option>
          <Option key={"2"}>Rawat Jalan</Option>
          <Option key={"3"}>IGD</Option>
          <Option key={"4"}>PenunjangMedis</Option>
          <Option key={"5"}>PenunjangNonMedis</Option>
          <Option key={"7"}>ApotikFarmasi</Option>
        </Select>
        <Select
          dataSource={unit}
          showSearch
          style={{ width: "100%" }}
          placeholder="Pilih Ruang..."
          optionFilterProp="children"
          onChange={(e) => {
            setRuang(e);
            loadPelayanan(e);
          }}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          {unit.map((d) => (
            <Option
              key={d.ruangId}
              className={d.deskripsi.includes("ABIYASA") ? "backgroundaby" : ""}
            >
              {d.ruangId + " - " + d.deskripsi}
            </Option>
          ))}
        </Select>
        <Select
          dataSource={pelayanan}
          showSearch
          // searchValue={kosong}
          style={{ width: "100%" }}
          placeholder="Pilih Pelayanan..."
          optionFilterProp="children"
          onChange={(e) => setPel(e)}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          {pelayanan.map((d) => (
            <Option key={d.pelayananId}>
              {d.pelayananId + " - " + d.deskripsi}
            </Option>
          ))}
        </Select>
        <Select
          dataSource={pembayaran}
          showSearch
          // searchValue={kosong}
          style={{ width: "100%" }}
          placeholder="Pilih Pembayaran..."
          optionFilterProp="children"
          onFocus={() => getPembayaran()}
          onChange={(e) => setPem(e)}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          {pembayaran.map((d) => (
            <Option key={d.pembayaranId}>
              {d.pembayaranId + " - " + d.deskripsi}
            </Option>
          ))}
        </Select>
        <Select
          dataSource={dokterall}
          showSearch
          style={{ width: "100%" }}
          placeholder="Pilih Pelaksana..."
          optionFilterProp="children"
          onChange={(e) => setDokter(e)}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          {dokterall.map((p) => (
            <Option key={p.dokterId}>
              {p.dokterId + " - " + p.namaDokter}
            </Option>
          ))}
        </Select>
        <Input placeholder="User" onChange={(e) => setUser(e.target.value)} />
        <Input
          placeholder="Client Name"
          onChange={(e) => setClient(e.target.value)}
        />
        <Input
          placeholder="Client IP"
          onChange={(e) => setIP(e.target.value)}
        />
        <Button
          type="primary"
          onClick={() => {
            insertBiiling(data);
            setLoad(true);
          }}
          block
        >
          Insert
        </Button>
        <Divider />
        <Table
          // style={{ verticalAlign: "top" }}
          locale={{ emptyText: <Empty description={false} /> }}
          bordered
          pagination={false}
          dataSource={billing}
          size="small"
          rowKey="reg"
          scroll={{ x: 1000 }}
          summary={(pageData) => {
            let total = 0;
            pageData.forEach(({ biayaPelayanan }) => {
              total += biayaPelayanan;
            });
            return (
              <>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th>Total</th>
                  <td className="column-money, tabeltabel" align="right">
                    <Text type="danger">
                      <div className="salary-cell">
                        <span className="currency">Rp.</span>
                        <span className="amount">
                          {Intl.NumberFormat("id", {
                            style: "currency",
                            currency: "IDR",
                            maximumFractionDigits: 2,
                          })
                            .format(total)
                            .replace("Rp", "")}
                        </span>
                      </div>
                    </Text>
                  </td>
                </tr>
              </>
            );
          }}
        >
          <Column
            style={{ verticalAlign: "top" }}
            title="No."
            key="reg"
            className="tabeltabel"
            // width="10px"
            render={(text, record, index) => <span>{index + 1}</span>}
          />
          <Column
            style={{ verticalAlign: "top" }}
            title="Kode"
            className="bgcolortunggu, tabeltabel2"
            // width="40px"
            render={(billing) => <span>{billing.pelayananId}</span>}
          />
          <Column
            style={{ verticalAlign: "top" }}
            title="Unit"
            className="tabeltabel"
            // width="180px"
            render={(billing) => <span>{billing.ruangDesk}</span>}
          />
          <Column
            style={{ verticalAlign: "top" }}
            title="Tanggal"
            className="tabeltabel"
            // width="75px"
            render={(billing) => <span>{billing.tanggal}</span>}
          />
          <Column
            style={{ verticalAlign: "top" }}
            title="Pelayanan"
            className="tabeltabel"
            // width="400px"
            render={(billing) => <span>{billing.pelayananDesk}</span>}
          />
          <Column
            style={{ verticalAlign: "top" }}
            title="Jumlah"
            // width="40px"
            className="column-money, tabeltabel"
            render={(billing) => <span>{billing.jumlah}</span>}
            align="right"
          />
          <Column
            style={{ verticalAlign: "top" }}
            title="Biaya Pelayanan"
            className="auto-width"
            render={(billing) => (
              <div className="salary-cell">
                <span className="currency">Rp.</span>
                <span className="amount">
                  {Intl.NumberFormat("id", {
                    style: "currency",
                    currency: "IDR",
                    maximumFractionDigits: 2,
                  })
                    .format(billing.harga)
                    .replace("Rp", "")}
                </span>
              </div>
            )}
          />
          <Column
            style={{ verticalAlign: "top" }}
            title="Total Biaya"
            className="auto-width"
            render={(billing) => (
              <div className="salary-cell">
                <span className="currency">Rp.</span>
                <span className="amount">
                  {Intl.NumberFormat("id", {
                    style: "currency",
                    currency: "IDR",
                    maximumFractionDigits: 2,
                  })
                    .format(billing.biayaPelayanan)
                    .replace("Rp", "")}
                </span>
              </div>
            )}
          />
          <Column
            style={{ verticalAlign: "top" }}
            title="Penjamin"
            className="tabeltabel"
            // width="150px"
            render={(billing) => <span>{billing.pembayaranDesk}</span>}
          />
          <Column
            style={{ verticalAlign: "top" }}
            title="Pemeriksa"
            className="tabeltabel"
            // width="250px"
            render={(billing) => <span>{billing.pemeriksaDesk}</span>}
          />
          <Column
            style={{ verticalAlign: "top" }}
            title="No Bayar"
            className="tabeltabel"
            render={(billing) => <span>{billing.noPembayaran}</span>}
          />
          <Column
            style={{ verticalAlign: "top" }}
            title="User"
            className="tabeltabel"
            render={(billing) => <span>{billing.userId}</span>}
          />
          <Column
            style={{ verticalAlign: "top" }}
            title="Action"
            className="tabeltabel"
            render={(billing) => (
              <Space>
                <Tooltip title="Hapus">
                  <Popconfirm
                    title="Anda Yakin Dihapus ?"
                    // onConfirm={(e) =>
                    //   onDelete(
                    //     billing.registrasiId,
                    //     billing.pelayananId,
                    //     billing.pemeriksaId,
                    //     billing.ruangId,
                    //     billing.billPelayananId
                    //   )
                    // }
                    // onCancel={(e) => cancel(e)}
                    okText="Ya"
                    cancelText="Tidak"
                  >
                    <Button
                      size="small"
                      type="text"
                      shape="circle"
                      icon={<DeleteTwoTone twoToneColor="#f5222d" />}
                    />
                  </Popconfirm>
                </Tooltip>
                <Tooltip title="Edit">
                  <Button
                    size="small"
                    type="text"
                    shape="circle"
                    // onClick={() => {
                    //   setModalEdit(true);
                    //   onPelayanan(billing.pelayananId);
                    //   setJumlah(billing.jumlah);
                    //   setPemeriksa(billing.pemeriksaId);
                    //   setRuang(billing.ruangDesk);
                    // }}
                    icon={<EditTwoTone twoToneColor="#52c41a" />}
                  />
                </Tooltip>
              </Space>
            )}
          />

          <Column
            style={{ verticalAlign: "top" }}
            title={
              <>
                Sinkron KHS{" "}
                <Popover
                  placement="topLeft"
                  title="Status Sinkron"
                  content={
                    <div>
                      <Checkbox checked={false} /> = Jika Transaksi Belum ada di
                      Data KHS, silahkan klik tombol uncheck.
                      <br />
                      <Checkbox checked={true} /> = Jika Transaksi Sudah ada di
                      Data KHS dan tidak dihapus dari KHS.
                    </div>
                  }
                  // arrow="center"
                >
                  <QuestionCircleTwoTone />
                </Popover>
              </>
            }
            className="tabeltabel"
            render={(billing) => (
              <span>
                {billing.IsSync !== null ? (
                  <Checkbox
                    checked={true}
                    onClick={() => message.info("Sudah sinkron!")}
                  />
                ) : (
                  <Checkbox
                    checked={false}
                    // onClick={() =>
                    //   syncBillingByIdPoli(
                    //     curpas.registrasiId,
                    //     billing.pelayananId,
                    //     billing.ruangId
                    //   )
                    // }
                  />
                )}
              </span>
            )}
          />
        </Table>
      </Card>
    </div>
  );
};

export default InsertBill;
