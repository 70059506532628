import { Card, DatePicker, Form, Select } from "antd";
import React, { useState } from "react";
import FormNIHSS from "./FormNIHSS";
import FormDisfagia from "./FormDisfagia";
import FormBraden from "./FormBraden";
import FormFungsiMenelan from "./FormFungsiMenelan";
import FormZScore from "./FormZScore";
import FormFungsiOtot from "./FormFungsiOtot";
import FormOedema from "./FormOedema";
import FormTrauma from "./FormTrauma";
const { Option } = Select;
const formItemLayoutFull = {
  labelCol: { span: 2 },
  wrapperCol: { span: 22 },
};

const FormPengkajianLain = () => {
  const [jnsPengkajian, setjnsPengkajian] = useState("");
  return (
    <div>
      <Card size="small">
        <Form.Item
          {...formItemLayoutFull}
          label="Pilih Pengkajian"
          style={{ marginBottom: 5 }}
        >
          <Select
            //   value={smftujuan}
            //   dataSource={listSpesialisDBRS}
            onChange={(e) => {
              // onSmftujuan(e);
              // setdokterTujuanId("");
              // console.log(e);
              setjnsPengkajian(e);
            }}
            //   disabled={formkonsul}
            showSearch
            style={{ width: "100%" }}
            placeholder="Pilih Pengkajian..."
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {/* {listSpesialisDBRS.map((d) => ( */}
            <Option key="NIHSS">Skala NIHSS</Option>
            <Option key="DISFAGIA">Skala Disfagia</Option>
            <Option key="BRADEN">Skala Braden</Option>
            <Option key="MENELAN">Fungsi Menelan</Option>
            <Option key="ZSCORE">Z Score</Option>
            <Option key="OTOT">Fungsi Otot</Option>
            <Option key="OEDEMA">Derajat Oedema</Option>
            <Option key="TRAUMA">Pengkajian Trauma</Option>
            <Option key="SOFA">Pengkajian SOFA</Option>

            {/* ))} */}
          </Select>
        </Form.Item>
      </Card>
      <Card size="small">
        {jnsPengkajian === "NIHSS" ? (
          <FormNIHSS />
        ) : jnsPengkajian === "DISFAGIA" ? (
          <FormDisfagia />
        ) : jnsPengkajian === "BRADEN" ? (
          <FormBraden />
        ) : jnsPengkajian === "MENELAN" ? (
          <FormFungsiMenelan />
        ) : jnsPengkajian === "ZSCORE" ? (
          <FormZScore />
        ) : jnsPengkajian === "OTOT" ? (
          <FormFungsiOtot />
        ) : jnsPengkajian === "OEDEMA" ? (
          <FormOedema />
        ) : jnsPengkajian === "TRAUMA" ? (
          <FormTrauma />
        ) : (
          <></>
        )}
      </Card>
    </div>
  );
};

export default FormPengkajianLain;
